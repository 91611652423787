import React, { useState, useEffect } from "react";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const deviceMarker = L.icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const DeviceMap = ({ device }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const mapStyles = {
    height: windowWidth < 768 ? "200px" : "400px",
    width: windowWidth < 768 ? "95%" : "80%",
    maxWidth: "90%",
    margin: "0 auto",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    borderRadius: "8px",
  };

  return (
    <MapContainer
      center={[device.latitude, device.longitude]}
      zoom={8}
      scrollWheelZoom={false}
      style={mapStyles}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker
        position={[device.latitude, device.longitude]}
        icon={deviceMarker}
      >
        <Tooltip>{device.name}</Tooltip>
      </Marker>
    </MapContainer>
  );
};

export default DeviceMap;

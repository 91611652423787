import React, { useEffect, useState } from 'react';
import styles from '../styles/Charts.module.css'; // Adjust the path as necessary

const EcoFLow = () => {
  const [latestData, setLatestData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Define your data mapping here
  const dataMapping = {
    soc: "Procenat baterije (%)",
    remainTime: "Preostalo (minuti)",
    wattsOutSum: "Potrošnja (W) ",
    wattsInSum: "Punjenje (W)"
    // You can add more mappings if needed
  };

  const defaultValues = {
    soc: "85",
    remainTime: "1375",
    wattsOutSum: "73",
    wattsInSum: "275"
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://api.ecoflow.com/iot-service/open/api/device/queryDeviceQuota?sn=DDEBZ5HF1140078', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'appKey': '837d30a70cf14f6db3ba7adc20b8f0a9', // Replace with your actual appKey
            'secretKey': 'c8e73975e91241fdad43dda4424c799e' // Replace with your actual secretKey
          }
        });
        const data = await response.json();
        if (data && data.code === "0" && data.data) {
          // Assuming you want the first item in the data array
          setLatestData(data.data);
        } else {
          console.error('Data fetch error: ', data.message);
          setLatestData(null);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <p>Učitavanje...</p>;
  }

  if (!latestData) {
    return (
      <div className={styles.latestDataWrapper}>
        {Object.entries(defaultValues)
          .map(([key, value]) => (
            <div key={key} className={styles.latestDataTop}>
              <strong>{dataMapping[key]}:</strong> {value}
              <i className={`fas fa-battery-half ${styles.icon}`}></i> {/* Adding the battery icon for each measurement */}
            </div>
          ))}
      </div>
    );
  }

  return (
    <div className={styles.latestDataWrapper}>
      {Object.entries(latestData)
        .filter(([key, value]) => dataMapping[key] && value !== null) // Filtering out keys not in dataMapping and null values
        .map(([key, value]) => (
          <div key={key} className={styles.latestDataTop}>
            <strong>{dataMapping[key]}:</strong> {value}
            <i className={`fas fa-battery-half ${styles.icon}`}></i> {/* Adding the battery icon for each measurement */}
          </div>
        ))}
    </div>
  );
};

export default EcoFLow;

import React, { useRef, useState } from "react";
import styles from "../styles/Login.module.css";
import { FaLock, FaUser } from "react-icons/fa";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import newLogo from '../styles/fzieng.png'; // Replace with the path to the new image
import logo1 from '../styles/fzieng.png'; // Replace with the path to the first logo
import logo2 from '../styles/digitalsmart_cropped800-removebg-preview.png';
import logo3 from '../styles/logosolar.png'; // Replace with the path to the second logo

const Login = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [loginFailed, setLoginFailed] = useState(false);

  const loginPress = async () => {
    setLoginFailed(1); // Indicate loading process
    // Here you can add your authentication logic or API call
    setTimeout(() => { // Simulate an async action, like an API call
      const username = usernameRef.current.value;
      const password = passwordRef.current.value;
      
      // Static check for username and password
      if (username === "solarkatun" && password === "solarkatun") {
        localStorage.setItem("token", "dummy-token");
        setIsLoggedIn(true); // Update the state in the parent component
        navigate("/home"); // Navigate to the homepage route
      } else {
        setLoginFailed(2); // Indicate login failed
      }
    }, 1000); // Set delay to simulate a network request
  };

  return (
    <div className={styles.loginWrapper}>
      <img src={newLogo} className={styles.newLogo} alt="New Logo" />
      <div className={styles.projectTitle}>SOLAR KATUN</div>
      <div className={styles.projectSubtitle}>Solar powered portable solution for precision agriculture in rural areas</div>
      
      <div className={styles.usernameWrapper}>
        <FaUser color="white" />
        <input
          ref={usernameRef}
          type="text"
          placeholder="Korisnicko ime"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              loginPress();
            }
          }}
        />
      </div>
      <div className={styles.passwordWrapper}>
        <FaLock color="white" />
        <input
          ref={passwordRef}
          type="password"
          placeholder="Lozinka"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              loginPress();
            }
          }}
        />
      </div>
      <button onClick={loginPress} className={styles.loginButton}>
        Prijavi se
      </button>
      <Popup
        open={loginFailed === 2}
        closeOnDocumentClick
        onClose={() => setLoginFailed(0)}
      >
        <div className={styles.loginModal}>
          {loginFailed === 1 ? (
            <>
              <span style={{ marginBottom: 20 }}>Molimo sacekajte</span>
              <ClipLoader color="var(--primary-color)" />
            </>
          ) : (
            <>
              Neuspjesna prijava
              <button
                onClick={() => setLoginFailed(0)}
                className={styles.closeModalButton}
              >
                Pokusajte opet
              </button>
            </>
          )}
        </div>
      </Popup>
      
      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <img src={logo1} className={styles.footerLogo1} alt="Logo 1" />
          <img src={logo3} className={styles.footerLogo3} alt="Logo 2" />
          <img src={logo2} className={styles.footerLogo2} alt="Logo 2" />
        </div>
      </footer>
      <div className={styles.projectDescription}>
        This is a Proof of Concept (PoC) project that is funded by a PoC grant from the Innovation Fund of Montenegro. The mobile solar power plant is equipped with a precision agriculture equipment for a typical application in rural areas in Montenegro.
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import DeviceMap from "./DeviceMap";
import styles from "../styles/Homepage.module.css";
import MeteoStanica from "./MeteoStanica";
import Meteo2 from "./Meteo2";
import IrigUredjaj from "./IrigUredjaj";
import EcoFLow from "./EcoFlow";
import fond from '../styles/digitalsmart_cropped800-removebg-preview.png';
import ds from '../styles/fzieng.png';
import sk from '../styles/logosolar.png';
import image1 from '../styles/krava.png'; // Replace with the path to the first image
import image2 from '../styles/kravadetektovano.png'; // Replace with the path to the second image

const HomePage = ({ onLogout }) => {
  const device = {
    latitude: 43.11354780755522,
    longitude: 19.098010646442166,
    name: "SolarKatun uredjaj",
  };

  const apiUrl = "https://api.agronet.solutions";
  const deviceId = "0004a30b010ac321";
  const deviceId2 = "0004a30b010a2bb5";
  const irig = "2cf7f1c053900055_1";

  const startDate = '2024-06-05'; // replace 2024 with the desired year if needed
  const endDate = '2024-06-15';

  const [activeSlide, setActiveSlide] = useState(0);

  const handleDeviceMap = () => setActiveSlide(0);
  const handleCamera1 = () => setActiveSlide(1);
  const handleCamera2 = () => setActiveSlide(2);

  return (
    <div className={styles.homepageWrapper}>
      <div className={styles.header}>
        <button onClick={onLogout} className={styles.logoutButton}>Odjava</button>
      </div>
      <div className={styles.content}>
        {activeSlide === 0 && <DeviceMap device={device} className="pond" />}
        {activeSlide === 1 && (
          <img src={image1} alt="Camera 1" className={styles.contentImage} />
        )}
        {activeSlide === 2 && (
          <img src={image2} alt="Camera 2" className={styles.contentImage} />
        )}
        <div className={styles.navigationButtons}>
          <button
            className={`${styles.navigationButton} ${activeSlide === 0 ? 'active' : ''}`}
            onClick={handleDeviceMap}
          >
            Mapa
          </button>
          <button
            className={`${styles.navigationButton} ${activeSlide === 1 ? 'active' : ''}`}
            onClick={handleCamera1}
          >
            Kamera
          </button>
          <button
            className={`${styles.navigationButton} ${activeSlide === 2 ? 'active' : ''}`}
            onClick={handleCamera2}
          >
            AI model
          </button>
        </div>
        <EcoFLow />
        <MeteoStanica apiUrl={apiUrl} deviceId={deviceId} startDate={startDate} endDate={endDate} />
        <Meteo2 apiUrl={apiUrl} deviceId={deviceId2} startDate={startDate} endDate={endDate} />
        <IrigUredjaj apiUrl={apiUrl} deviceId={irig} startDate={startDate} endDate={endDate} />
      </div>
      <footer className={styles.footer}>
        <img src={ds} alt="Digital Smart Logo" className={styles.footerImage2} />
        <img src={sk} alt="Eco Logo" className={styles.footerImage3} />
        <img src={fond} alt="Eco Logo" className={styles.footerImage1} />
      </footer>
      <footer className={styles.footer1}>
        <div className={styles.projectDescription}>
          This is a Proof of Concept (PoC) project that is funded by a PoC grant from the Innovation Fund of Montenegro. The mobile solar power plant is equipped with a precision agriculture equipment for a typical application in rural areas in Montenegro.
        </div>
      </footer>
    </div>
  );
};

export default HomePage;

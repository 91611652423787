import React, { useEffect, useState } from 'react';
import styles from '../styles/Charts.module.css'; // Adjust the path as necessary

const Meteo2 = ({ apiUrl, deviceId, startDate, endDate }) => {
  const [latestData, setLatestData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dataMapping = {
    windSpeed: "Brzina vjetra (m/s)",
    windDirection: "Pravac vjetra (°)",
    battery: "Baterija (mV)",
    solarPanel: "Solarni panel (W)"
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiUrl}/SolarKatun/GetMeteoData?deviceid=${deviceId}&startdate=${startDate}&enddate=${endDate}`, {
          method: 'GET',
          headers: {
            'SolarKatunKey': '57inhvfuet5769e@45r' // Your custom header here
          }
        });
        const data = await response.json();
        if (data && data.length) {
          // Assuming the last object in the array is the latest
          setLatestData(data[data.length - 1]);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [apiUrl, deviceId, startDate, endDate]);

  if (isLoading) {
    return <p>Učitavanje...</p>;
  }

  if (!latestData) {
    return <p style={{ color: 'white' }}>Nema dostupnih podataka.</p>;
  }

  return (
    <div className={styles.latestDataWrapper}>
      {Object.entries(latestData)
        .filter(([key, value]) => dataMapping[key] !== undefined && value !== null) // Filtering out keys not in dataMapping and null values
        .map(([key, value]) => {
          // Check for negative solar panel values
          if (key === 'solarPanel' && value < 0) {
            value = 0;
          }
          return (
            <div key={key} className={styles.latestDataTop}>
              <strong>{dataMapping[key]}:</strong> {value}
              <i className={`fas fa-wind ${styles.icon}`}></i> {/* Adding the wind icon */}
            </div>
          );
        })}
    </div>
  );
};

export default Meteo2;
